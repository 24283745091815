import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react-pro'
import './scss/style.scss'

// We use those styles to show code examples, you should remove them in your application.
import './scss/examples.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-pro-react-admin-template-theme-modern',
  )
  const storedTheme = useSelector((state) => state.theme)

  // componentDidMount() {
  //   console.log("testing app");
  //   console.log(document.location.hostname);
  //   if(!!localStorage["token"] === true){

  //   } else {
  //     window.location.href = `#login`;
  //   }
 
  //   // var hostname = document.location.hostname
    
  // }



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]

    // var hostname = document.location.hostname
    // console.log(hostname)
    // // console.log("Существует")

    // if (!!localStorage["api_url"] === true) {
    //   console.log("Существует")
    
    //   if (hostname === 'dev.dmxdev.ru') {
    //     localStorage.setItem('api_url', "http://api.dmxdev.ru")
    //   }
    //   if (hostname === 'dmxdev.ru') {
    //     localStorage.setItem('api_url', "http://api.dmxdev.ru")
    //   }
    //   // dmxdev.ru
    // } else {
    //   console.log("Не существует")
    //   // var hostname = document.location.hostname

    //   if (hostname === 'dev.dmxdev.ru') {
    //     localStorage.setItem('api_url', "http://api.dmxdev.ru")
    //   }
    //   if (hostname === 'dmxdev.ru') {
    //     localStorage.setItem('api_url', "http://api.dmxdev.ru")
    //   }
    // }

    const hostname = document.location.hostname;
    const protocol = document.location.protocol; 
    
    console.log(`Hostname: ${hostname}, Protocol: ${protocol}`); 
    
    let apiUrl = localStorage.getItem('api_url');
    
    // if (apiUrl) {
    //   console.log("API URL exists"); 
    // } else {
      // console.log("API URL doesn't exist"); 
    
    // Устанавливаем API URL в зависимости от хоста и протокола
    apiUrl = hostname === 'dev.dmxdev.ru' || hostname === 'dmxdev.ru' ?
      (protocol === 'https:' ? 'https://api.dmxdev.ru' : 'http://api.dmxdev.ru') :
      hostname === 'admin.dmx.kz' ? (protocol === 'https:' ? 'https://api.dmx.kz' : 'http://api.dmx.kz') : null;

    console.log(apiUrl)

    if (apiUrl) {
      localStorage.setItem('api_url', apiUrl);
    }

    // }
    
    if(!!localStorage["token"] === true){

    } else {
      window.location.href = `#login`;
    }


    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }
    setColorMode(storedTheme)
  }, [])

  return (
    <HashRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
